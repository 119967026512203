import React from 'react';
import './App.css';
import './styles/index.scss';
import SettingContextProvider from './context/SettingsContext';
import Router from './routes';
import { HelmetProvider } from 'react-helmet-async';
import { StyledEngineProvider } from "@mui/material/styles";
import ConfiguredThemeProvider from "./theme/ThemeProvider";

function App() {
    return (
        <HelmetProvider>
            <SettingContextProvider>
                        <ConfiguredThemeProvider>
                            <StyledEngineProvider injectFirst>
                                <Router />
                            </StyledEngineProvider>
                        </ConfiguredThemeProvider>
            </SettingContextProvider>
        </HelmetProvider>
    );
}

export default App;